      /* @font-face {
          font-family: eduarizona;
          src: url(../EduArizonaSans-Bold.otf);
      }

      @font-face {
          font-family: eduarizonaRegular;
          src: url(../EduArizonaSans-Regular.otf);
      }

       *{
          padding: 0;
          margin: 0;
      }  */

      .custom-head p {
        color: #1b1340;
    }
      .cell_sec {
          width: 100%;
          height: auto;
      }

      .cell_sec .slick-dots li {
          position: relative;
          display: inline-block;
          width: 10px !important;
          height: 10px !important;
          margin: 0 4px;
          cursor: pointer;
      }

      .cell_sec .slick-dots li button {
          font-size: 0 !important;
          width: 10px !important;
          height: 10px !important;
          cursor: pointer;
          background: #fff !important;
          border-radius: 50px;
      }


      .cell_sec .slick-dots li button:before {
          content: "" !important;

          border-radius: 3px;
          z-index: -1 !important;
          opacity: 0 !important;
      }

      .cell_sec .slick-dots li.slick-active button {
          opacity: 1 !important;
          background: #383848 !important;
      }

      /* body {
           padding: 0; 
          background-color: #ddd;
          font-family: eduarizona;
          overflow: hidden;
      } */

      .cell_sec .protonworking {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: 10px;
          margin: 0 auto;
          padding: 10px;
          box-sizing: border-box;
          max-width: 940px;
          /* margin-top: 100px; */
      }

      .cell_sec .grid_items {
          width: 48%;
          position: relative;
          height: auto !important;
      }

      /* p {
          font-family: eduarizonaRegular;
      } */

      .cell_sec .tabs {
          /* border-left: 2px solid #383848; */
          display: flex;
          flex-direction: column;
      }

      .cell_sec .tab {
          position: relative;
          /* border-left: 2px solid #383848; */
          color: #1b1340;
          padding-bottom: 30px;
      }

      .cell_sec .heading {
          font-size: 28px;
          line-height: 12px;
          cursor: pointer;
          margin-left: 42px;
          display: flex;
          align-items: center;
      }

      .cell_sec .h_span {
          background: #383848;
          color: #9696a5;
          padding: 9px 12px 14px 12px !important;
          border-radius: 2px;
          font-size: 25px;
          margin-right: 20px;
          text-align: center;
          position: absolute;
          left: -23.71px;
          line-height: 1;
          width: 45px;
          height: 45px;
      }

      .cell_sec #h_span1 {
          color: white;
      }

      .cell_sec .grid_img {
          position: relative;
      }

      .cell_sec .grid_img img {
          position: absolute;
          top: -50px;

          /* height: 485px; */
      }

      .cell_sec .cellimg {
          transform: scale(1.3);
      }

      /* .slider_img {
    transform: translateX(-70px);
} */
      .cell_sec .slider_h2 {
          padding: 6px 13.8px !important;
      }

      .cell_sec .slider_h3 {
          padding: 6px 13.8px !important;
      }

      .cell_sec .slider_h4 {
          padding: 6px 13.2px !important;
      }

      .cell_sec .tab_p {
          margin-left: 45px;
          /* padding-top: 10px; */
          font-weight: 200;
          /* font-size: 18px; */
          /* line-height: 22px; */
          letter-spacing: 0.34px;
          overflow: hidden;
      }

      .cell_sec .tab1_p {
          transition: all 0.5s ease;
          animation: slide 0.5s 1 ease-in-out;
          height: 88px;
      }

      .cell_sec .tab2_p,
      .cell_sec .tab3_p,
      .cell_sec .tab4_p {
          height: 0;
          opacity: 0;
          transition: all 0.5s ease;
      }

      .cell_sec #img2,
      .cell_sec #img3,
      .cell_sec #img4 {
          display: none;
          transition: opacity 0.5s ease-in-out;
      }

      .cell_sec .how {
          display: none;
      }

      .cell_sec .extra {
          visibility: hidden;
      }

      @media (max-width: 970px) {
          .cell_sec .protonworking {
              padding: 10px 10px 10px 20px;
          }
      }

      .cell_sec img {
          width: 485px;
          height: 485px;
      }

      /* desktop code ends */

      /* slider code */
      .cell_sec .slick-dots {
          display: flex;
          gap: 22px;
          list-style: none;
          justify-content: center;
      }

      .cell_sec .slick-dots button {
          padding: 2px 5px;
          border-radius: 3px;
          border: none;
          cursor: pointer;
      }

      .cell_sec .slick-slider .slick-disabled {
          opacity: 0;
          pointer-events: none;
      }

      /* .grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
      }

      .slider_img img {
          transform: scale(1.1) !important;
      } */

      .cell_sec p.slider_h {
          margin-bottom: 10px;
          font-size: 20px;
          background: transparent !important;
          color: #000 !important;
          display: inline-block;
          padding: 5px 15px;
          border-radius: 7px !important;
          border: 2px solid #383848;
      }

      .cell_sec .slick-dots {
          margin-top: 15px;
      }

      .cell_sec .slider_head {
          font-size: 20px;
          /* font-family: eduarizonaRegular; */
          line-height: 24px;
          margin-top: 10px;
      }

      .cell_sec .slider_p {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.34px;
          color: #2b2b36;
          margin-top: 8px;
      }

      .cell_sec .slick-active button {
          background: #383848;
          color: white;
      }


      @media (max-width: 950px) {
          .cell_sec .protonworking {
              display: none;
          }

          .cell_sec .how {
              display: block;
          }

          .cell_sec .slider_img img {
              transform: scale(1.4) !important;
              width: 100%;
              height: 100%;
          }

          .cell_sec .grid {
              display: grid;
              grid-template-columns: 1fr 1fr;
              align-items: center;
              justify-content: center;
              max-width: 720px;
              margin: 0 auto;
              padding-top: 50px 0px;
              padding: 50px 30px 50px 0px;
          }

          .cell_sec .slider_p {
              width: 83%;
          }
      }

      @media (max-width: 652px) {
          .cell_sec .grid {
              padding: 80px 50px 80px 0px;
          }

          .cell_sec .slider_p {
              width: 95%;
          }

          .cell_sec .slider_img img {
              transform: scale(1.8) !important;
              width: 100%;
              height: 100%;
          }
      }

      @media (max-width: 550px) {
          .cell_sec .how {
              margin-top: 17px;
          }

          .cell_sec .slider_head {
              font-size: 16px;
          }

          /* .slider_p {
            font-size: 12px;
            width: 93%;
        } */

          .cell_sec p.slider_h {
              font-size: 17px;
          }
      }

      @media (max-width: 520px) {
          .cell_sec .slider_img img {
              transform: scale(1.8) !important;
          }

          .cell_sec .grid {
              padding: 80px 50px 50px 0px;
          }
      }

      @media (max-width: 480px) {
        body .slick-list {
            margin: 0 20px;
        }
          .cell_sec .slider_img img {
              transform: scale(2.2) !important;
              padding: 0px 10px 0px 10px;
          }

          .cell_sec .grid {
              padding: 50px 20px 50px 0px;
           
          }

          .cell_sec .slider_p {
              width: 100%;
          }

          .cell_sec .slick-dots {
            position: absolute;
            bottom: -30px;

        }
      }

      @media (max-width: 380px) {
        body .slick-list {
            margin: 0 16px;
        }
          .cell_sec .slider_img img {
              transform: scale(2.4) !important;
          }

      }


      @media (max-width: 320px) {
      
        .cell_sec .grid {
            padding: 30px 0px 20px 0px;
        }

      }